import { createContext } from "react";

enum Site {
  SintOedenrode,
  Veghel,
}

const siteToText = (site: Site) => {
  switch (site) {
    case Site.SintOedenrode:
      return "Sint-Oedenrode";
    case Site.Veghel:
      return "Veghel";
  }
};

const siteToPath = (site: Site) => {
  switch (site) {
    case Site.SintOedenrode:
      return "/sint-oedenrode";
    case Site.Veghel:
      return "/veghel";
  }
};

const SiteContext = createContext(Site.SintOedenrode);

export { Site, siteToText, siteToPath };
export default SiteContext;
