import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from "./routes/root";
import ErrorPage from "./error-page";
import Register from "./routes/register";
import "./sass/visitor-registration.scss";
import Home from "./routes/home";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./i18n";
import { Site } from "./site-context";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const router = createBrowserRouter([
  {
    path: "/veghel",
    element: <Root site={Site.Veghel} />,
    errorElement: <ErrorPage />,

    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "in",
        element: <Register />,
      },
    ],
  },
  {
    path: "/sint-oedenrode",
    element: <Root site={Site.SintOedenrode} />,
    errorElement: <ErrorPage />,

    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "in",
        element: <Register />,
      },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
    <ToastContainer position="bottom-center" autoClose={3000} closeOnClick draggable draggableDirection="y" />
  </React.StrictMode>
);
