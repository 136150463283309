import { useTranslation } from "react-i18next";
import { Link, Outlet } from "react-router-dom";
import SiteContext, { Site, siteToPath } from "../site-context";

export type RootProps = {
  site: Site;
};

export default function Root(props: RootProps) {
  const { t, i18n } = useTranslation();

  return (
    <div className="visitor-registration">
      <div className="header">
        <Link to={siteToPath(props.site)}>
          <img src={props.site === Site.Veghel ? "/logo-logistics.png" : "/logo-koelenvries.png"} />
        </Link>
        <div className="language-changer">
          <span
            className={`badge rounded-pill ${i18n.language === "nl-NL" ? "text-bg-primary" : "text-bg-light"}`}
            onClick={() => i18n.changeLanguage("nl-NL")}>
            nl
          </span>
          <span
            className={`badge rounded-pill ${i18n.language === "en" ? "text-bg-primary" : "text-bg-light"}`}
            onClick={() => i18n.changeLanguage("en")}>
            en
          </span>
        </div>
      </div>
      <div className="content">
        <SiteContext.Provider value={props.site}>
          <Outlet />
        </SiteContext.Provider>
      </div>
    </div>
  );
}
