import { FormEvent, useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import HouseRulesPdf from "../HUISREGELS.pdf";
import SiteContext, { Site, siteToPath, siteToText } from "../site-context";

interface Employee {
  id: number;
  name: string;
}

export default function Register() {
  const { t } = useTranslation();
  const site = useContext(SiteContext);
  const [saving, setSaving] = useState(false);
  const [employees, setEmployees] = useState<Array<Employee>>([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetch(process.env.REACT_APP_API_PREFIX + "/employees")
      .then(r => r.json())
      .then(emps => {
        setEmployees(emps);
      })
      .catch(() => {
        toast.error(t("error"));
      });
  }, []);

  const register = async (form: HTMLFormElement) => {
    const data = new FormData(form).entries();

    let values: string[] = [];
    for (var [key, value] of data) {
      values.push(`${value}`);
    }

    await fetch(process.env.REACT_APP_API_PREFIX + "/in", {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({
        companyName: values[0],
        fullName: values[1],
        visitingEmployeeId: values[2],
        site: siteToText(site),
      }),
    })
      .then(r => r.json())
      .then(regId => {
        localStorage.setItem("registration-id", regId);
        navigate(siteToPath(site));
        toast.success(`${t("register.welcome")} ${values[1]}!`);
      })
      .catch(() => {
        toast.error(t("error"));
        setSaving(false);
      });
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    let form = event.target as HTMLFormElement;

    const valid = form.checkValidity();
    form.classList.add("was-validated");

    if (valid) {
      setSaving(true);
      await register(form);
    }
  };

  return (
    <div className="register">
      <form onSubmit={handleSubmit} className="needs-validation" noValidate={true}>
        <div className="form-row">
          <label htmlFor="company">{t("register.company")}</label>
          <input type="text" className="form-control" name="company" id="company" required={true} />
        </div>
        <div className="form-row">
          <label htmlFor="name">{t("register.name")}</label>
          <input type="text" className="form-control" name="name" id="name" required={true} />
        </div>
        <div className="form-row">
          <label htmlFor="visiting">{t("register.visiting")}</label>
          <select name="visiting" className="form-select" id="visiting" required={true} defaultValue="">
            <option key={0} disabled={true} value="">
              {t("register.visitingPlaceholder")}
            </option>
            {employees.map(e => (
              <option key={e.id} value={e.id}>
                {e.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-row">
          <label htmlFor="site">{t("register.location")}</label>
          <input type="text" className="form-control" disabled={true} name="site" id="site" value={siteToText(site)} />
        </div>
        <div className="form-row form-check">
          <label htmlFor="house-rules" className="form-check-label">
            <Trans i18nKey="register.readAndUnderstandHouseRules">{["", <a target="_blank" href={HouseRulesPdf}></a>, ""]}</Trans>
          </label>
          <input type="checkbox" className="form-check-input" required={true} name="house-rules" id="house-rules" />
        </div>
        <button disabled={saving} type="submit" className="btn btn-success w-100">
          {t("register.register")}
        </button>
      </form>
    </div>
  );
}
