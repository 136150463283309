import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    lng: "nl-NL",
    fallbackLng: "nl-NL",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          error: "Something went wrong...",
          home: {
            visit: "Visit",
            leave: "Leave",
            goodbye: "Goodbye",
          },
          register: {
            company: "Company",
            name: "Name",
            visiting: "Visiting",
            visitingPlaceholder: "Select an employee...",
            location: "Location",
            register: "Register",
            welcome: "Welcome",
            readAndUnderstandHouseRules: "I read and understand <1>the house rules</1>.",
          },
        },
      },
      nl: {
        translation: {
          error: "Er is iets misgegaan...",
          home: {
            visit: "Bezoek",
            leave: "Verlaat",
            goodbye: "Tot ziens",
          },
          register: {
            company: "Bedrijf",
            name: "Naam",
            visiting: "Bezoek aan",
            visitingPlaceholder: "Selecteer een medewerker...",
            location: "Locatie",
            register: "Registreer",
            welcome: "Welkom",
            readAndUnderstandHouseRules: "Ik heb <1>de huisregels</1> gelezen en begrijp ze.",
          },
        },
      },
    },
  });

export default i18n;
