import { MouseEvent, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function Home() {
  const { t } = useTranslation();
  const [registrationId, setRegistrationId] = useState(localStorage.getItem("registration-id"));

  const clockOut = async (event: MouseEvent) => {
    event.preventDefault();
    await fetch(process.env.REACT_APP_API_PREFIX + "/out?" + new URLSearchParams({ id: `${registrationId}` }), {
      method: "POST",
      headers: { "Content-type": "application/json" },
    }).then(() => {
      setRegistrationId(null);
      localStorage.removeItem("registration-id");
      toast.success(`${t("home.goodbye")}!`);
    });
  };

  return (
    <div className="home">
      <Link to="in" className="btn btn-success">
        {t("home.visit")}
        <i className="fa-solid fa-right-to-bracket" />
      </Link>
      {registrationId !== null ? (
        <Link to="/" onClick={event => clockOut(event)} className="btn btn-danger">
          {t("home.leave")}
          <i className="fa-solid fa-right-from-bracket" />
        </Link>
      ) : (
        <Link to="/" onClick={event => event.preventDefault()} className="btn btn-secondary disabled">
          {t("home.leave")}
          <i className="fa-solid fa-right-from-bracket" />
        </Link>
      )}
    </div>
  );
}
